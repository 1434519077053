import { render, staticRenderFns } from "./LegalInfoForm.vue?vue&type=template&id=11fcb44b&"
import script from "./LegalInfoForm.vue?vue&type=script&lang=js&"
export * from "./LegalInfoForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports