<template>
    <div class="container">
        <h1>{{$t('legalInfo.heading')}}</h1>
        <div
            class="p-4 bg-white border-light rounded shadow"
        >
            <legal-info-form
                :client-type="clientType"
                :defaults="legalInfo || {}"
                :ivan-hash-id="ivanHashId"
                :other-items="otherItems"
                @submit="onSubmit"
                @cancel="onCancel"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewBase from '../ViewBase.vue'
import LegalInfoForm from './Components/LegalInfoForm.vue'

export default {
    name: 'LegalInfo',
    extends: ViewBase,
    components: {
        LegalInfoForm
    },
    computed: {
        ...mapGetters([
            'otherItems'
        ]),
        legalInfo () {
            const legalInfo = ((this.sessionData) ? this.sessionData.legalInfo : {}) || {}
            return {
                ...legalInfo,
                activateIB: (this.sessionData && this.sessionData.activateIB)
            }
        },
        clientType () {
            return (this.sessionData) ? this.sessionData.clientType : null
        }
    },
    methods: {
        onSubmit (data) {
            const { activateIB, ...legalInfo } = data
            this.saveSessionData({ activateIB, legalInfo: { ...legalInfo, clientType: this.sessionData.clientType } })
                .then(() => {
                    this.$store.commit('setDataHash', { name: 'legalInfoOld', hash: null })
                    this.$store.commit('setDataHash', { name: 'legalInfo', hash: null })
                    this.$emit('next')
                })
        },
        onCancel () {
            this.$emit('previous')
        }
    }
}
</script>
