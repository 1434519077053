<template>
    <div>
        <h3>{{ title }}</h3>
        <form-input-checkbox-group
            :id="`otherItems.${title}`"
            v-model="selectedItems"
            :options="itemsOptions"
            :validation="selectedItemsValidation"
        />
        <form-input v-show="selectedItems.includes('other')"
            :id="`otherItems.${title}.other`"
            v-model="other"
            :label="otherLabel"
            :validation="otherValidation"
        />
    </div>
</template>

<script>
export default {
    name: 'OtherItemsCheckboxGroup',
    props: {
        title: {
            type: String,
            required: true
        },
        itemsOptions: {
            type: Array,
            required: false,
            default: () => []
        },
        value: {
            type: Array,
            required: false,
            default: () => []
        },
        otherValue: {
            type: String,
            required: false,
            default: null
        },
        selectedItemsValidation: {
            type: Object,
            required: false,
            default: () => {}
        },
        otherValidation: {
            type: Object,
            required: false,
            default: () => {}
        },
        otherLabel: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        selectedItems: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
                if (!value.includes('other') && this.other) {
                    this.other = null
                }
            }
        },
        other: {
            get () {
                return this.otherValue
            },
            set (value) {
                this.$emit('update:other', value)
            }
        }

    }
}
</script>
